import { useMediaQuery } from "~/spocketUI/hooks/useMediaQuery";
import * as S from "./styles";

export type SeparatorType = "horizontal" | "vertical";

type Props = {
  type: SeparatorType;
  margin?: number;
  className?: string;
};

const Separator = ({ type, margin = 0, className}: Props) => {
  if (type === "horizontal") return <S.HorizontalSeparator className={className} margin={margin} />;
  return <S.VerticalSeparator className={className} margin={margin} />;
};

export default Separator;
