export const Stack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36">
      <path
        fill="currentColor"
        d="m4.604 11.257 13.041 7.608 13.042-7.608L17.645 3.65 4.604 11.257Z"
        opacity=".2"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.174"
        d="m4.604 24.3 13.041 7.607 13.042-7.608"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.174"
        d="m4.604 17.778 13.041 7.608 13.042-7.608"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.174"
        d="m4.604 11.257 13.041 7.608 13.042-7.608L17.645 3.65 4.604 11.257Z"
      />
    </svg>
  );
};
