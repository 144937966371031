export const SHOW_PLANS_MODAL = 'show-plans-modal';
export const SHOW_RESUME_MODAL = 'show-resume-modal';

export const triggerShowPlansModal = (
  hideCloseButton = false,
  initialIsAnnual = false
) => {
  const event = new CustomEvent(SHOW_PLANS_MODAL, {
    detail: { hideCloseButton, initialIsAnnual },
  });
  window.dispatchEvent(event);
};

export const triggerShowResumeModal = () => {
  const event = new CustomEvent(SHOW_RESUME_MODAL, {});
  window.dispatchEvent(event);
}
