import {
  faCog,
  faHouseChimney,
  faFilePen,
  faDrawSquare,
  faFileLines,
  faSquareInfo,
  faPaintbrushPencil
} from "@fortawesome/pro-light-svg-icons";
import { paths } from "~/router/paths";
import { INavItem } from "~/types/routing";

export const navItems: Array<INavItem> = [
  {
    namePath: "nav.home",
    path: paths.app.home,
    icon: faHouseChimney,
  },
  {
    namePath: "product.product_editor",
    path: paths.product.index,
    icon: faFilePen,
  },
  {
    namePath: "ads.ads_writer",
    path: paths.ads.index,
    icon: faDrawSquare,
  },
  {
    namePath: "blog.blog_writer",
    path: paths.blog.index,
    icon: faFileLines,
  },
  {
    namePath: "nav.create_your_logo",
    path: "https://www.logome.ai/?utm_source=smartli",
    openInNewTab: true,
    icon: faPaintbrushPencil,
    newFlag: true,
  },
  {
    namePath: "settings.title",
    path: paths.settings.plans,
    icon: faCog,
  },
  {
    namePath: "nav.help_center",
    path: "https://help.smartli.ai/Smartli-3a54244151e346c0b295e3710c522208",
    openInNewTab: true,
    icon: faSquareInfo,
    // Uncomment this to show help center as a modal
    // showHelpCenterModal: true,
  },
];
