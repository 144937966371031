import { FC, ReactNode } from "react";
import * as S from "./styles";
import { SVG } from "../SVG";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { Portal } from "~/spocketUI/helpers/portal";

export interface IModalProps {
  isShowing: boolean;
  hide: () => void;
  children: ReactNode;
  padding?: string;
  minWidth?: string | number;
}

const Modal: FC<IModalProps> = ({ isShowing, hide, children, padding, minWidth }) =>
  isShowing ? (
    <Portal>
      <S.ModalWrapper onClick={hide}>
        <S.ModalContent minWidth={minWidth ? minWidth : "30%"} padding={padding} onClick={(e) => e.stopPropagation()}>
          <S.CloseContainer onClick={hide}>
            <SVG icon={faClose} size="xl" />
          </S.CloseContainer>
          {children}
        </S.ModalContent>
      </S.ModalWrapper>
    </Portal>
  ) : null;

export default Modal;
