import ProgressBar from "~/spocketUI/components/ProgressBar";
import FlexContainer from "~/spocketUI/components/FlexContainer";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Button from "~/spocketUI/components/Button";
import { useTranslation } from "react-i18next";
import { updateUserPreferences } from "~/api/account/requests";
import { useAccount } from "~/hooks/useAccount";
import { MARKETPLACES } from "./constants";
import { triggerShowPlansModal } from "~/spocketUI/helpers/customEvents";
import { SVG } from "~/spocketUI/components/SVG";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { SVGIcon } from "~/spocketUI/components/SVG/types";

type Props = {
  backStep: () => void;
};

export const SelectMarketPlace = ({ backStep }: Props) => {
  const { t } = useTranslation();
  const { account, getAccount } = useAccount();
  const [selectedMarketplaces, setSelectedMarketplaces] = useState<string[]>(
    []
  );

  useEffect(() => {
    if(account?.marketplaces?.length && !selectedMarketplaces.length) {
      setSelectedMarketplaces(account.marketplaces);
    }
  }, [account?.marketplaces]);

  const newMarketplaces = MARKETPLACES
    ? [...MARKETPLACES, { name: t("onboarding.noStore"), value: "no_store" }]
    : [];

  const handleNoStoreSelection = () => {
    setSelectedMarketplaces((prev) => {
      if (prev.includes("no_store")) {
        return [];
      } else {
        return ["no_store"];
      }
    });
  };

  const handleMarketplaceSelection = (value: string) => {
    setSelectedMarketplaces((prev) => {
      if (prev.includes(value)) {
        return prev.filter((catValue) => catValue !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const handleMarketplaceSelect = (catValue: string) => {
    if (catValue === "no_store") {
      handleNoStoreSelection();
    } else {
      handleMarketplaceSelection(catValue);
    }
  };

  const handleNextStep = () => {
    triggerShowPlansModal();
  };

  const handleContinue = async () => {
    try {
      await updateUserPreferences({
        marketplaces: selectedMarketplaces,
      });
      getAccount();
      handleNextStep();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FlexContainer
        flexDirection="column"
        gap="0"
        width="100%"
        alignItems="flex-start">
        <S.SelectPreferenceTitle>
          <Button
            bgColor="transparent"
            color="text"
            padding="0px 10px 2px 0px"
            onClick={() => backStep()}>
            <SVG icon={faChevronLeft as SVGIcon} />
          </Button>
          {t("onboarding.marketplace_title")}
        </S.SelectPreferenceTitle>
        <ProgressBar progress={100} color="primary" />
      </FlexContainer>
      <FlexContainer gap="16px" flexWrap="wrap" width="100%">
        {newMarketplaces?.map((category) => (
          <S.CardPreference
            key={category.value}
            className={
              selectedMarketplaces?.includes(category.value) ? "active" : ""
            }
            onClick={() => handleMarketplaceSelect(category.value)}>
            {category.name}
          </S.CardPreference>
        ))}
      </FlexContainer>
      <FlexContainer justifyContent="space-between" width="100%">
        <Button
          bgColor="transparent"
          color="textSecondary"
          padding="16px 24px"
          onClick={handleNextStep}>
          {t("onboarding.skip")}
        </Button>
        <Button
          bgColor="primary"
          color="white"
          padding="12px 30px"
          radius={3}
          onClick={handleContinue}>
          {t("onboarding.continue")}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};
