export const GENERATION_PREFERENCES = [
  { name: "Product Descriptions", value: "product_descriptions" },
  { name: "Blog Posts", value: "blog_posts" },
  { name: "Facebook Ads", value: "facebook_ads" },
  { name: "Instagram Posts", value: "instagram_posts" },
  { name: "Google Ads", value: "google_ads" },
  { name: "Tiktok Ads", value: "tiktok_ads" },
  { name: "Logos", value: "logos" },
];

export const MARKETPLACES = [
  { name: "Shopify", value: "shopify" },
  { name: "Wix", value: "wix" },
  { name: "BigCommerce", value: "bigcommerce" },
  { name: "Amazon", value: "amazon" },
  { name: "Ebay", value: "ebay" },
];