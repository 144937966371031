import { toast } from "react-toastify";

type ToastMessage = {
  loading: string;
  success: string;
  error: string;
};

type ResponseWithError = {
  response: any;
  errors: null | string[];
};

const handleErrors = async (
  promise: () => Promise<any>,
  toasts: ToastMessage
): Promise<ResponseWithError> => {
  const toastId = toast.info(toasts.loading);

  try {
    const response = await promise();
    toast.update(toastId, {
      type: "success",
      render: toasts.success,
    });
    return { response, errors: null };
  } catch (error: any) {
    const handleFormErrors = (errorData: Record<string, string[]>) => {
      const newErrors: Record<string, string> = {};
      Object.keys(errorData).forEach((key) => {
        newErrors[key] = errorData[key][0];
      });
      return Object.values(newErrors);
    };

    let errors: string[] | null = [];
    const errorData = error.response?.status;

    if (errorData === 400 || 402) {
      const message =
        error.response?.data?.msg ||
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.response?.data?.detail;

      if (message) {
        toast.update(toastId, {
          type: "error",
          render: message,
        });

        return { response: null, errors: [message] };
      }

      if (/text\/html/.test(error?.response?.headers?.["content-type"])) {
        toast.update(toastId, {
          type: "error",
          render: toasts.error,
        });
      } else {
        errors = handleFormErrors(error?.response?.data);

        toast.update(toastId, {
          type: "error",
          render: errors[0],
        });
      }
    } else {
      toast.update(toastId, {
        type: "error",
        render: toasts.error,
      });
    }

    return {
      response: null,
      errors: errors.length ? errors : [error.toString()],
    };
  }
};

export default handleErrors;
