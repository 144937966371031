export const Package = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36">
      <path
        fill="currentColor"
        d="M4.887 10.845c-.099.167-.15.357-.15.551v13.401a1.086 1.086 0 0 0 .553.947l11.955 6.725c.163.091.346.14.533.14h.002l.127-14.512-13.02-7.253Z"
        opacity=".2"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.174"
        d="M30.82 24.797v-13.4a1.086 1.086 0 0 0-.554-.948L18.311 3.724a1.087 1.087 0 0 0-1.066 0L5.29 10.45a1.086 1.086 0 0 0-.554.947v13.401a1.087 1.087 0 0 0 .554.947l11.955 6.725a1.087 1.087 0 0 0 1.066 0l11.955-6.725a1.086 1.086 0 0 0 .554-.947Z"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.174"
        d="M24.438 21.427v-7.065l-13.181-7.27"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.174"
        d="m30.67 10.846-12.763 7.25-13.02-7.252M17.907 18.097l-.128 14.512"
      />
    </svg>
  );
};
