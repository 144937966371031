// @ts-ignore
import { ReactComponent as LoaderSVG } from "~/spocketUI/assets/svg/loader.svg";
import * as S from "./styles";

type Props = { fullWidth?: boolean };

const Loader = ({ fullWidth }: Props) => {
  return (
    <S.LoaderContainer fullWidth={fullWidth}>
      <LoaderSVG />
    </S.LoaderContainer>
  );
};

export default Loader;
