export const Rocket = () => {
  return (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        d="M13.0326 25.4632C11.4956 30.0742 5.34766 30.0742 5.34766 30.0742C5.34766 30.0742 5.34766 23.9262 9.95864 22.3893L13.0326 25.4632Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M18.8233 24.2834L24.2417 18.8651V25.013L19.848 29.4067L18.8233 24.2834Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.17364"
      />
      <path
        opacity="0.2"
        d="M10.4092 11.1801H16.5572L11.1388 16.5985L6.01553 15.5738L10.4092 11.1801Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2.17364"
      />
      <path
        d="M13.0326 25.4632C11.4956 30.0742 5.34766 30.0742 5.34766 30.0742C5.34766 30.0742 5.34766 23.9262 9.95864 22.3893"
        stroke="currentColor"
        stroke-width="2.17364"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.866 14.7042L17.6441 23.9262L11.4961 17.7782L20.7181 8.55627C24.21 5.0643 27.702 5.09836 29.1912 5.32055C29.4205 5.35473 29.6328 5.46167 29.7967 5.6256C29.9606 5.78952 30.0676 6.00178 30.1018 6.23107C30.324 7.72031 30.358 11.2123 26.866 14.7042Z"
        stroke="currentColor"
        stroke-width="2.17364"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.3285 16.2412V25.013C25.3285 25.1558 25.3004 25.2971 25.2458 25.4289C25.1912 25.5608 25.1111 25.6806 25.0102 25.7815L20.6165 30.1752C20.4774 30.3143 20.3032 30.4131 20.1124 30.4609C19.9215 30.5088 19.7213 30.5039 19.5331 30.4469C19.3448 30.3899 19.1755 30.2829 19.0433 30.1372C18.9111 29.9916 18.8209 29.8128 18.7823 29.6199L17.6436 23.9262"
        stroke="currentColor"
        stroke-width="2.17364"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.181 10.0933H10.4092C10.2665 10.0933 10.1252 10.1214 9.99333 10.176C9.86147 10.2306 9.74166 10.3107 9.64074 10.4116L5.24703 14.8053C5.10794 14.9444 5.0092 15.1186 4.96135 15.3094C4.9135 15.5002 4.91834 15.7005 4.97534 15.8887C5.03235 16.077 5.13938 16.2463 5.28504 16.3785C5.43069 16.5107 5.60949 16.6009 5.80239 16.6395L11.4961 17.7782"
        stroke="currentColor"
        stroke-width="2.17364"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
