import { ILimit, Limits } from "~/types/billing";
import { useAccount } from "./useAccount";

const booleanFeatures = [
  Limits.ADS_WRITER, Limits.CHAT, Limits.PREMIUM_KEYWORDS, Limits.SAVE_DESCRIPTIONS
];

export const usePlanFeature = () => {
  const { account } = useAccount();

  const isFeatureEnabled = (limitType: Limits) => {
    if(!account) return false;

    const limits: ILimit | Record<any, any> =
      account?.active_subscription?.plan?.limits || {};

    if (account?.active_subscription?.paused_at) return false;

    if (limitType === Limits.PAID_PLAN) {
      return !!account?.active_subscription?.plan;
    }

    if (booleanFeatures.includes(limitType)) {
      return limits[limitType];
    }

    if (limitType === Limits.BLOG_GENERATED) {
      return account.blog_generation_count < limits[limitType];
    }

    switch (limitType as Limits) {
      case Limits.PAID_PLAN:
        return !!limits;
      case Limits.BLOG_GENERATED:
      return account.blog_generation_count < limits[limitType];
      case Limits.DESCRIPTIONS_GENERATED:
        return account.description_generation_count < limits[limitType];
      case Limits.GENERATION_COUNT_LIMIT:
        return true;
    }

    if (!limits) return false;
    // @ts-ignore
    return !!limits[limitType];
  };

  const isFeatureDisabled = (value: Limits) => !isFeatureEnabled(value);

  const isFeaturePaused = () => !!account?.active_subscription?.paused_at;

  return { isFeatureEnabled, isFeatureDisabled, isFeaturePaused };
};
