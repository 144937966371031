import ProgressBar from "~/spocketUI/components/ProgressBar";
import FlexContainer from "~/spocketUI/components/FlexContainer";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Button from "~/spocketUI/components/Button";
import { useTranslation } from "react-i18next";
import { updateUserPreferences } from "~/api/account/requests";
import { GENERATION_PREFERENCES } from "./constants";
import { SelectMarketPlace } from "./SelectMarketplace";
import { useAccount } from "~/hooks/useAccount";

export const SelectPreference = () => {
  const { t } = useTranslation();
  const { account, getAccount } = useAccount();
  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([]);
  const [showMarketplaces, setShowMarketplaces] = useState(false);

  useEffect(() => {
    if (
      account?.generation_preferences?.length &&
      !selectedPreferences.length
    ) {
      setSelectedPreferences(account.generation_preferences);
    }
  }, [account?.generation_preferences]);

  const newPreferences = GENERATION_PREFERENCES
    ? [...GENERATION_PREFERENCES, { name: t("onboarding.all"), value: "all" }]
    : [];

  const areAllPreferencesSelected = () => {
    return (
      selectedPreferences.length === GENERATION_PREFERENCES.length &&
      selectedPreferences.every((catValue: string) =>
        GENERATION_PREFERENCES.some((category) => category.value === catValue)
      )
    );
  };

  const handleAllSelection = () => {
    if (areAllPreferencesSelected()) {
      setSelectedPreferences([]);
    } else {
      setSelectedPreferences(
        GENERATION_PREFERENCES.map((category) => category.value)
      );
    }
  };

  const handlePreferenceSelection = (value: string) => {
    setSelectedPreferences((prev: any[]) => {
      if (prev.includes(value)) {
        return prev.filter((catValue) => catValue !== value);
      } else {
        const newPreferences = prev.includes(null) ? [] : [...prev];
        return [...newPreferences, value];
      }
    });
  };

  const handlePreferenceSelect = (catValue: string) => {
    if (catValue === "all") {
      handleAllSelection();
    } else {
      handlePreferenceSelection(catValue);
    }
  };

  const handleNextStep = () => {
    setShowMarketplaces(true);
  };

  const handleContinue = async () => {
    try {
      await updateUserPreferences({
        generation_preferences: selectedPreferences,
      });
      getAccount();
      handleNextStep();
    } catch (error) {
      console.error(error);
    }
  };

  if (showMarketplaces) {
    return <SelectMarketPlace backStep={() => setShowMarketplaces(false)} />;
  }

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FlexContainer
        flexDirection="column"
        gap="0"
        width="100%"
        alignItems="flex-start">
        <S.SelectPreferenceTitle>
          {t("onboarding.category_title")}
        </S.SelectPreferenceTitle>
        <ProgressBar progress={50} color="primary" />
      </FlexContainer>
      <FlexContainer gap="16px" flexWrap="wrap" width="100%">
        {newPreferences?.map((category) => (
          <S.CardPreference
            key={category.value}
            className={
              selectedPreferences?.includes(category.value) ? "active" : ""
            }
            onClick={() => handlePreferenceSelect(category.value)}>
            {category.name}
          </S.CardPreference>
        ))}
      </FlexContainer>
      <FlexContainer justifyContent="space-between" width="100%">
        <Button
          bgColor="transparent"
          color="textSecondary"
          padding="16px 24px"
          onClick={handleNextStep}>
          {t("onboarding.skip")}
        </Button>
        <Button
          bgColor="primary"
          color="white"
          padding="12px 30px"
          radius={3}
          onClick={handleContinue}>
          {t("onboarding.continue")}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};
