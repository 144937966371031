import React, { useEffect, useState } from "react";
import * as S from "./PageWrapper.style";
import { UpgradeButton } from "./UpgradeButton";
import { TryForFreeBanner } from "./TryForFreeBanner";
import { usePlanFeature } from "~/hooks/usePlanFeature";
import { Limits } from "~/types/billing";
import { Notifications } from "~/components/notifications";
import { useMediaQuery } from "~/spocketUI/hooks/useMediaQuery";
import { PlansModal } from "~/components/plans/plansModal";
import { ResumeModal } from "~/components/resumeModal";
import Modal from "~/spocketUI/components/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "~/hooks/useAccount";
import { SelectPreference } from "../onboarding/SelectPreference";
import { Portal } from "~/spocketUI/helpers/portal";

interface IProps {
  children: React.ReactNode;
}

export const PageWrapper: React.FC<IProps> = ({ children = null }) => {
  const { isFeatureDisabled } = usePlanFeature();
  const location = useLocation();
  const isSettingsPage = location.pathname.includes("settings");
  const isNotMobile = useMediaQuery("tablet");
  const { account, getAccount } = useAccount();
  const isAboveDesktop = useMediaQuery("desktop");
  const queryParams = new URLSearchParams(location.search);
  const [isShowingPreferences, setIsShowingPreferences] = useState(false);
  const navigate = useNavigate();
  const planId = queryParams.get("plan_id");
  const showRating = queryParams.get("show_rating");
  const onboarding = queryParams.get("onboarding");

  useEffect(() => {
    const checkOnboardingCondition = async () => {
      const account = await getAccount();

      if (onboarding && account && !account?.active_subscription) {
        setIsShowingPreferences(true);
      }

      const searchParams = new URLSearchParams(window.location.search);

      if (
        account &&
        !account?.active_subscription &&
        !account?.has_subscribed_before &&
        !planId &&
        !showRating
      ) {
        searchParams.set("onboarding", "true");
        navigate(`?${searchParams.toString()}`);
      } else {
        setIsShowingPreferences(false);
        searchParams.delete("onboarding");
        const queryParams = searchParams.toString();
        const newUrl = window.location.pathname + (!!queryParams ? `?${queryParams}` : "");
        window.history.replaceState(null, "", newUrl);
      }
    };

    checkOnboardingCondition();
  }, [planId, onboarding]);

  return (
    <S.PageWrapper
      padding={isSettingsPage ? "24px 25px 58px" : "24px 92px 58px"}>
      <S.PageHeader>
        {isFeatureDisabled(Limits.PAID_PLAN) ? <UpgradeButton /> : null}
        {isNotMobile ? <Notifications /> : null}
      </S.PageHeader>
      <S.PageMaxWidth>
        <TryForFreeBanner />
        <>{children}</>
      </S.PageMaxWidth>
      {isShowingPreferences ? (
        <Portal>
          <S.ModalWrapper>
            <S.ModalContent minWidth={isAboveDesktop ? "66%" : "80%"}>
              <SelectPreference />
            </S.ModalContent>
          </S.ModalWrapper>
        </Portal>
      ) : null}
      <PlansModal />
      <ResumeModal />
    </S.PageWrapper>
  );
};

export const PageWrapperFullScreen: React.FC<IProps> = ({
  children = null,
}) => {
  return (
    <S.PageWrapperFullscreen>
      <PlansModal />
      <ResumeModal />
      <>{children}</>
    </S.PageWrapperFullscreen>
  );
};
