import { IPlan, SubscriptionType, subscriptionTypeToBoolean } from "~/types/billing";

export const centsToDecimal = (cents: number): number => {
  return cents / 100;
};

export const decimalToCents = (decimal: number): number => {
  return decimal * 100;
};

export const getTotalPlanCost = (plan: IPlan | null | undefined) => {
  const costPerMonth = plan?.cost_per_month
    ? centsToDecimal(plan?.cost_per_month)
    : 0;
  const annualValue = costPerMonth * 12;
  const isAnnual = plan?.interval === SubscriptionType.ANNUAL;
  return isAnnual ? annualValue : costPerMonth;
};
