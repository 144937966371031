import path from "path";
import { lazy } from "react";
import { app } from "~/helpers/routes";
import { paths } from "~/router/paths";

const HomePage = lazy(() => import("~/pages/home"));

export const routes: Array<any> = [
  {
    path: paths.app.home,
    element: app(HomePage),
  },
];
